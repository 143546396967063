import React from 'react';

import { header } from './faq-list.module.scss';

import IFaq from '../../models/faq.model';
import IFaqCategory from '../../models/faq-category.model';

import FaqElement from '../molecules/faq-element';
import Heading from '../atoms/heading';

interface IFaqListProps {
    faqList: IFaq[];
    faqCategory: IFaqCategory;
    className?: string;
    openList?: { id: number; isOpen: boolean }[];
    onChange?: (index: number) => void;
}

const FaqList: React.FC<IFaqListProps> = ({
    faqList,
    faqCategory,
    className = '',
    openList = [],
    onChange = () => null,
}) => {
    return (
        <div className={className}>
            <Heading numberOfStyle={4} className={header}>
                {faqCategory.name}
            </Heading>
            {faqList.map((faq) => {
                return (
                    <FaqElement
                        key={faq.faqId}
                        faq={faq}
                        open={openList?.find((el) => el.id === faq.faqId)?.isOpen}
                        onChange={onChange}
                        index={faq.faqId}
                    />
                );
            })}
        </div>
    );
};

export default FaqList;
