import { graphql, useStaticQuery } from 'gatsby';

import { IMenu } from '../models/menu.model';
import { IQueryAllResult } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import getMenuPaginationData from '../utils/get-menu-pagination-data';

interface IStaticQueryResult {
    allMenu: IQueryAllResult<IMenu>;
}

export default function useMenuPaginationData(pathname: string) {
    const data = useStaticQuery<IStaticQueryResult>(query);
    return getMenuPaginationData(data.allMenu, pathname);
}

const query = graphql`
    query {
        allMenu {
            edges {
                node {
                    links
                }
            }
        }
    }
`;
