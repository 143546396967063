import React, { useEffect, useState } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

import {
    wrapper,
    button,
    accessibilityWrapper,
    content,
    icon,
    hidden,
} from './expandable-area.module.scss';

import AccessibleContent from '../atoms/accessible-content';

import PlusIcon from '../../assets/images/svg/plus.svg';
import MinusIcon from '../../assets/images/svg/minus.svg';

interface IExpandableAreaProps {
    isInitiallyOpen?: boolean;
    hideButtonWhenExpanded?: boolean;
    ButtonContent: React.ReactNode;
    ExpandableContent: React.ReactNode;
    className?: string;
    buttonClass?: string;
    contentClass?: string;
    ariaId?: string;
    ariaLabel?: string;
    onChange?: (index: number) => void;
    index?: number;
}

const ExpandableArea: React.FC<IExpandableAreaProps> = ({
    isInitiallyOpen = false,
    hideButtonWhenExpanded = false,
    ButtonContent,
    ExpandableContent,
    className = '',
    buttonClass = '',
    contentClass = '',
    ariaId = '',
    ariaLabel = '',
    onChange = () => null,
    index = 0,
}) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);
    const [height, setHeight] = useState<Height>(0);

    useEffect(() => {
        setIsOpen(isInitiallyOpen);
        setHeight(isInitiallyOpen ? 'auto' : 0);
    }, [isInitiallyOpen]);

    const handleClick = () => {
        setIsOpen((value) => {
            setHeight(!value ? 'auto' : 0);
            onChange(index);
            return !value;
        });
    };

    const isButtonHidden = isOpen && hideButtonWhenExpanded;

    return (
        <div className={`${className} ${wrapper}`}>
            <button
                disabled={isButtonHidden}
                onClick={handleClick}
                className={`${button} ${buttonClass} ${isButtonHidden ? hidden : ''}`}
                aria-controls={ariaId}
                aria-label={ariaLabel}
                aria-expanded={isOpen}
            >
                <AccessibleContent
                    className={accessibilityWrapper}
                    screenReadersContent={ButtonContent}
                    Tag={'span'}
                >
                    {ButtonContent}
                    {isOpen ? <MinusIcon className={icon} /> : <PlusIcon className={icon} />}
                </AccessibleContent>
            </button>
            <AnimateHeight duration={500} height={height} id={ariaId}>
                <div className={`${content} ${contentClass}`}>{ExpandableContent}</div>
            </AnimateHeight>
        </div>
    );
};

export default ExpandableArea;
