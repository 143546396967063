import React from 'react';

import { header, button } from './faq-element.module.scss';

import IFaq from '../../models/faq.model';

import ExpandableArea from './expandable-area';
import Heading from '../atoms/heading';
import Markdown from '../hoc/markdown';

interface IFaqElementProps {
    faq: IFaq;
    open?: boolean;
    onChange?: (index: number) => void;
    index?: number;
}

const FaqElement: React.FC<IFaqElementProps> = ({
    faq,
    open = false,
    onChange = () => null,
    index = 0,
}) => {
    return (
        <ExpandableArea
            onChange={onChange}
            index={index}
            isInitiallyOpen={open}
            buttonClass={button}
            ButtonContent={
                <Heading className={header} numberOfStyle={6}>
                    {faq.question}
                </Heading>
            }
            ExpandableContent={<Markdown>{faq.answer}</Markdown>}
        />
    );
};

export default FaqElement;
