import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { IPageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    grid,
    container,
    breadcrumbs,
    header,
    button,
    accessibleWrapper,
    icon,
    list,
} from './faq.module.scss';

import useTranslations from '../hooks/use-translations';
import { getNodes } from '../utils/get-nodes';
import { IQueryAllResult } from '../models/query-all-result.model';
import IFaq from '../models/faq.model';
import IFaqCategory from '../models/faq-category.model';
import useMenuPaginationData from '../hooks/use-menu-pagination-data';

import MainLayout from '../layouts/main-layout';
import Heading from '../components/atoms/heading';
import Button from '../components/atoms/button';
import FaqList from '../components/organisms/faq-list';
import AccessibleContent from '../components/atoms/accessible-content';
import Pagination from '../components/molecules/pagination';

import PlusIcon from '../assets/images/svg/plus.svg';
import MinusIcon from '../assets/images/svg/minus.svg';

interface IFaqPageProps {
    readonly data: {
        allFaq: IQueryAllResult<IFaq>;
        allFaqCategory: IQueryAllResult<IFaqCategory>;
    };
    readonly pageContext: IPageContext;
}

const FAQ: React.FC<IFaqPageProps> = ({ data, pageContext }) => {
    const { pathname } = pageContext;
    const allFaq = getNodes(data.allFaq);
    const allFaqCategories = getNodes(data.allFaqCategory);
    const paginationData = useMenuPaginationData(pathname);
    const t = useTranslations('Faq');
    const [isOpenArray, setIsOpenArray] = useState(
        allFaq.map((faq) => {
            return {
                id: faq.faqId,
                isOpen: false,
            };
        })
    );

    const areAllOpen = checkArrayValue(isOpenArray, true);

    const handleClick = () => {
        setIsOpenArray((state) =>
            state.map((el) => {
                return {
                    ...el,
                    isOpen: !areAllOpen,
                };
            })
        );
    };

    const handleChange = (id: number) => {
        setIsOpenArray((state) =>
            state.map((el) => {
                return {
                    ...el,
                    isOpen: el.id === id ? !el.isOpen : el.isOpen,
                };
            })
        );
    };

    return (
        <MainLayout className={grid} breadcrumbsClassName={breadcrumbs}>
            <div className={container}>
                <div className={header}>
                    <Heading numberOfStyle={2}>{t.header}</Heading>
                    <Button
                        className={button}
                        theme={'dark'}
                        isOutlined={true}
                        onClick={handleClick}
                    >
                        <AccessibleContent
                            className={accessibleWrapper}
                            screenReadersContent={areAllOpen ? t.buttonClose : t.buttonOpen}
                        >
                            {areAllOpen ? (
                                <>
                                    {t.buttonClose} <MinusIcon className={icon} />
                                </>
                            ) : (
                                <>
                                    {t.buttonOpen} <PlusIcon className={icon} />
                                </>
                            )}
                        </AccessibleContent>
                    </Button>
                </div>
                {allFaqCategories.map((category) => {
                    return (
                        <FaqList
                            key={category.categoryId}
                            className={list}
                            faqList={allFaq.filter((faq) =>
                                faq.categoriesIds.includes(category.categoryId)
                            )}
                            faqCategory={category}
                            openList={isOpenArray}
                            onChange={handleChange}
                        />
                    );
                })}
                <Pagination as="page" {...paginationData} />
            </div>
        </MainLayout>
    );
};

function checkArrayValue(array: { isOpen: boolean }[], value: boolean) {
    return array.every((element) => element.isOpen === value);
}

export const query = graphql`
    query {
        allFaq(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...faqFields
                }
            }
        }
        allFaqCategory(sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...faqCategoriesFields
                }
            }
        }
    }
`;

export default FAQ;
