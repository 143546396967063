import { IQueryAllResult } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { IMenu } from '../models/menu.model';
import { getNodes } from './get-nodes';

const getMenuPaginationData = (menu: IQueryAllResult<IMenu>, currentPathname: string) => {
    const pages = getPageGroup(getNodes(menu), (item) => item.pathname === currentPathname);

    const currentPageIndex = pages?.findIndex((page) => page.pathname === currentPathname) ?? -1;

    return {
        prev: createPaginationLink(pages, currentPageIndex, -1),
        next: createPaginationLink(pages, currentPageIndex, 1),
    };
};

function getPageGroup(menu: IMenu[], callback: (item: IMenu) => boolean): IMenu[] {
    for (const item of menu) {
        if (callback(item)) {
            return menu;
        }

        if (item.links.length > 0) {
            const group = getPageGroup(item.links, callback);
            if (group.length === 0) {
                continue;
            }
            return group;
        }
    }
    return [];
}

const createPaginationLink = (pages: IMenu[], currentPageIndex: number, direction: number) => {
    if (currentPageIndex < 0) {
        return { pathname: '' };
    }

    const page = pages[currentPageIndex + direction];

    return {
        pathname: page?.pathname || '',
        title: page?.label || undefined,
    };
};

export default getMenuPaginationData;
