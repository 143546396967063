import React, { useRef } from 'react';

import { container, link, prev, next } from './pagination.module.scss';
import ArrowIcon from '../../assets/images/svg/pagination-arrow.svg';
import useTranslations from '../../hooks/use-translations';
import { TTranslationsPagination } from '../../locale/en';

import Button from '../atoms/button';
import AccessibleContent from '../atoms/accessible-content';

interface IPaginationData {
    keys: number[];
    activeIndex: number;
    prevLink: string;
    prevLinkTitle: string;
    prevLinkLabel: string;
    nextLink: string;
    nextLinkTitle: string;
    nextLinkLabel: string;
}

interface IPaginationListingProps {
    as: 'listing';
    pageNumber: number;
    pageCount: number;
    paginationPaths: string[];
}

interface IPaginationPageProps {
    as: 'page' | 'post';
    prev: { pathname: string; title?: string };
    next: { pathname: string; title?: string };
}

export type TPaginationProps = {
    className?: string;
    linksClassName?: string;
} & (IPaginationListingProps | IPaginationPageProps);

const Pagination: React.FC<TPaginationProps> = (props) => {
    const t = useTranslations('Pagination');
    const paginationData: IPaginationData = useRef({
        keys: [],
        activeIndex: 0,
        prevLink: '',
        prevLinkTitle: t.prevLinkTitle,
        prevLinkLabel: '',
        nextLink: '',
        nextLinkTitle: t.nextLinkTitle,
        nextLinkLabel: '',
    }).current;

    const { as, className = '', linksClassName = '' } = props;

    switch (as) {
        case 'page':
        case 'post':
            const { prev, next } = props;

            createPrevLink({ prev, paginationData });
            createNextLink({ next, paginationData });

            break;

        case 'listing':
            const { paginationPaths, pageCount, pageNumber } = props;

            createKeys(pageCount, paginationData);
            createActiveIndex(pageNumber, paginationData);

            createPrevLink({ paginationPaths, paginationData });
            createNextLink({ paginationPaths, paginationData });
    }

    createLabels(as, paginationData, t);

    if (!paginationData.prevLink && !paginationData.nextLink) return null;

    return (
        <div className={`${container} ${className}`}>
            {paginationData.prevLink && (
                <Button
                    kind={'text'}
                    as={'link'}
                    to={paginationData.prevLink}
                    theme={'dark'}
                    className={`${linksClassName} ${prev}`}
                >
                    <AccessibleContent
                        Tag={'span'}
                        screenReadersContent={paginationData.prevLinkLabel}
                        className={link}
                    >
                        <ArrowIcon />
                        {paginationData.prevLinkTitle}
                    </AccessibleContent>
                </Button>
            )}

            {paginationData.nextLink && (
                <Button
                    kind={'text'}
                    as={'link'}
                    to={paginationData.nextLink}
                    theme={'dark'}
                    className={`${linksClassName} ${next}`}
                >
                    <AccessibleContent
                        Tag={'span'}
                        screenReadersContent={paginationData.nextLinkLabel}
                        className={link}
                    >
                        {paginationData.nextLinkTitle}
                        <ArrowIcon />
                    </AccessibleContent>
                </Button>
            )}
        </div>
    );
};

const createKeys = (
    pageCount: IPaginationListingProps['pageCount'],
    paginationData: IPaginationData
): void => {
    paginationData.keys = [...Array(pageCount).keys()].map((el) => el + 1);
};

const createActiveIndex = (
    pageNumber: IPaginationListingProps['pageNumber'],
    paginationData: IPaginationData
): void => {
    paginationData.activeIndex = paginationData.keys.indexOf(pageNumber);
};

interface ICreatePrevLink {
    paginationData: IPaginationData;
    prev?: IPaginationPageProps['prev'];
    paginationPaths?: IPaginationListingProps['paginationPaths'];
}

const createPrevLink = ({ paginationData, prev, paginationPaths }: ICreatePrevLink): void => {
    if (prev) {
        paginationData.prevLink = prev.pathname || '';
        paginationData.prevLinkTitle = prev.title ? prev.title : paginationData.prevLinkTitle;
    }

    if (paginationPaths) {
        paginationData.prevLink = paginationPaths[paginationData.activeIndex - 1];
    }
};

interface ICreateNextLink {
    paginationData: IPaginationData;
    next?: IPaginationPageProps['next'];
    paginationPaths?: IPaginationListingProps['paginationPaths'];
}

const createNextLink = ({ paginationData, next, paginationPaths }: ICreateNextLink): void => {
    if (next) {
        paginationData.nextLink = next.pathname || '';
        paginationData.nextLinkTitle = next.title ? next.title : paginationData.nextLinkTitle;
    }

    if (paginationPaths) {
        paginationData.nextLink = paginationPaths[paginationData.activeIndex + 1];
    }
};

const createLabels = (
    as: TPaginationProps['as'],
    paginationData: IPaginationData,
    t: TTranslationsPagination
) => {
    switch (as) {
        case 'page' || 'post':
            paginationData.prevLinkLabel = paginationData.prevLinkTitle
                ? t.titleLabel({ title: paginationData.prevLinkTitle, as })
                : t.prevNoTitleLabel({ as });
            paginationData.nextLinkLabel = paginationData.nextLinkTitle
                ? t.titleLabel({ title: paginationData.nextLinkTitle, as })
                : t.nextNoTitleLabel({ as });
            break;

        case 'listing':
            const key = paginationData.keys[paginationData.activeIndex];

            paginationData.prevLinkLabel = t.listingLabel({ key: `${key - 1}` });
            paginationData.nextLinkLabel = t.listingLabel({ key: `${key + 1}` });
    }
};

export default Pagination;
